import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import 'tw-elements';
import App from './App';
// import { worker } from './mock/browser';


const env = String(process.env.REACT_APP_NODE_ENV);
// const isMock = ['mock'].includes(env);
const isProd = ['prod', 'production'].includes(env);

// if (isMock) {
//   worker.start();
// }

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
// root.render(
//   <React.StrictMode>
//     <App/>
//   </React.StrictMode>
// )
root.render(
  isProd
    ? <App/>
    : <React.StrictMode><App/></React.StrictMode>
)


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
