import React, { LazyExoticComponent, ReactComponentElement } from 'react'
import { RoutesModel } from '@bloxima/ui-library';
import Sign from '../views/Sign'

export const authRoute: RoutesModel[] = [
  {
    key: 'signIn',
    path: '/sign-in',
    component: React.lazy(() => import('../views/auth/SignIn')),
    authority: []
  },
  {
    key: 'signUp',
    path: '/sign-up',
    component: React.lazy(() => import('../views/auth/SignUp')),
    authority: []
  },
  {
    key: 'forgotPassword',
    path: '/forgot-password',
    component: React.lazy(() => import('../views/auth/ForgotPassword')),
    authority: []
  },
  {
    key: 'resetPassword',
    path: '/reset-password',
    component: React.lazy(() => import('../views/auth/ResetPassword')),
    authority: []
  },
  {
    key: 'sign',
    path: '/sign/:id',
    component: React.lazy(() => import('../views/Sign')),
    authority: []
  },
  {
    key: 'claim',
    path: '/claim/:id',
    component: React.lazy(() => import('../views/Claim')),
    authority: []
  }
]
