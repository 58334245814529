import { IconBaseProps } from 'react-icons'
import { PiFlowArrowBold } from 'react-icons/pi'
import { BiImages, BiLogoBitcoin, BiSolidNetworkChart } from 'react-icons/bi'
import { IoIosSettings } from 'react-icons/io'
import { VscFileBinary } from 'react-icons/vsc'
import { LuLayoutDashboard } from 'react-icons/lu'
import { TbCircleDot } from 'react-icons/tb'
import { RiTokenSwapLine } from 'react-icons/ri';

const navigationIcon = {
  home: (props: IconBaseProps) => LuLayoutDashboard(props),
  application: (props: IconBaseProps) => TbCircleDot(props),
  endpoint: (props: IconBaseProps) => PiFlowArrowBold(props),
  flow: (props: IconBaseProps) => BiSolidNetworkChart(props),
  log: (props: IconBaseProps) => VscFileBinary(props),
  web3: (props: IconBaseProps) => BiLogoBitcoin(props),
  settings: (props: IconBaseProps) => IoIosSettings(props),
  nft: (props: IconBaseProps) => BiImages(props),
  tokenization: (props: IconBaseProps) => RiTokenSwapLine(props),

}

export default navigationIcon
