import { NavigationConfigModel, NavigationItemTypes } from '@bloxima/ui-library';

const navigationConfig: NavigationConfigModel[] = [
  {
    key: 'dashboard',
    path: '/',
    title: 'Panel',
    icon: 'home',
    type: NavigationItemTypes.ITEM,
    authority: [],
    subMenu: []
  },
  {
    key: 'application',
    path: '/application',
    title: 'Uygulama',
    icon: 'application',
    type: NavigationItemTypes.ITEM,
    authority: [],
    subMenu: []
  },
  {
    key: 'endpoint',
    path: '/endpoint',
    title: 'Uç Nokta',
    icon: 'endpoint',
    type: NavigationItemTypes.ITEM,
    authority: [],
    subMenu: []
  },
  {
    key: 'flow',
    path: '/flow',
    title: 'İş Akışı',
    icon: 'flow',
    type: NavigationItemTypes.ITEM,
    authority: [],
    subMenu: []
  },
  // {
  //   key: 'log',
  //   path: '/log',
  //   title: 'Log',
  //   icon: 'log',
  //   type: NavigationItemTypes.ITEM,
  //   authority: [],
  //   subMenu: []
  // },


  {
    key: 'web3',
    path: '/web3',
    title: 'Web3',
    icon: 'web3',
    type: NavigationItemTypes.ITEM,
    authority: [],
    subMenu: []
  },
  {
    key: 'tokenization',
    path: '',
    title: 'Tokenization',
    icon: 'tokenization',
    type: NavigationItemTypes.COLLAPSE,
    authority: [],
    subMenu: [
      {
        key: 'tokenization',
        path: '/tokenization/create',
        title: 'Oluştur',
        icon: '',
        type: NavigationItemTypes.ITEM,
        authority: [],
        subMenu: []
      }

    ]
  },

  {
    key: 'nft',
    path: '',
    title: 'NFT',
    icon: 'nft',
    type: NavigationItemTypes.COLLAPSE,
    authority: [],
    subMenu: [
      {
        key: 'collections',
        path: '/collections',
        title: 'Koleksiyon Yarat',
        icon: '',
        type: NavigationItemTypes.ITEM,
        authority: [],
        subMenu: []
      },
      {
        key: 'create',
        path: '/nft/create',
        title: 'NFT Yarat',
        icon: '',
        type: NavigationItemTypes.ITEM,
        authority: [],
        subMenu: []
      },
      {
        key: 'nft-view',
        path: '/report/nft-view',
        title: 'Görüntüle',
        icon: '',
        type: NavigationItemTypes.ITEM,
        authority: [],
        subMenu: []
      }
    ]
  },
  {
    key: 'rapor',
    path: '',
    title: 'Raporlar',
    icon: 'log',
    type: NavigationItemTypes.COLLAPSE,
    authority: [],
    subMenu: [
      {
        key: 'flow-log',
        path: '/report/flow-log',
        title: 'İş Akış Log',
        icon: '',
        type: NavigationItemTypes.ITEM,
        authority: [],
        subMenu: []
      },
      {
        key: 'event-log',
        path: '/report/event-log',
        title: 'Event Log',
        icon: '',
        type: NavigationItemTypes.ITEM,
        authority: [],
        subMenu: []
      }
    ]
  },
  {
    key: 'settings',
    path: '/settings',
    title: 'Ayarlar',
    icon: 'settings',
    type: NavigationItemTypes.ITEM,
    authority: [],
    subMenu: []
  }
]

export default navigationConfig;

