import { ApiService } from '@bloxima/ui-library'
import {
  NotificationSettingsDto,
  NotificationSettingsReqDto,
  NotificationUpdateSettingsDto
} from './dto/notificationSettingsDto'
import { CollectionReqDto } from './dto/collectionsDto';
import { UnreadNotificationsResDto } from './dto/notificationDto';

export const NotificationsService = {
  async getAllNotifications(userId: string): Promise<NotificationSettingsDto> {
    const response = await ApiService.fetchData<void, NotificationSettingsDto>({
      url: `/api/${userId}/NotificationSetting`,
      method: 'GET'
    })
    return response.data
  },

  async updateNotifications(userId: string, dto: NotificationUpdateSettingsDto): Promise<void> {
    const data = {
      dto
    }

    await ApiService.fetchData<{ dto: NotificationUpdateSettingsDto }, void>({
      url: `/api/${userId}/application`,
      method: 'PUT',
      data
    })
  },

  async createNotifications(userId: string, dto: NotificationSettingsReqDto): Promise<void> {
    await ApiService.fetchData<NotificationSettingsReqDto, void>({
      url: `/api/${userId}/NotificationSetting`,
      method: 'POST',
      data: dto
    })
  },

  async getUnreadNotificationCount(userId: string): Promise<number> {
    const response = await ApiService.fetchData<void, number>({
      url: `/api/${userId}/Notification/unread/count`,
      method: 'GET'
    })
    return response.data;
  },

  async getAllUndreadNotifications(userId: string): Promise<UnreadNotificationsResDto[]> {
    const response = await ApiService.fetchData<void, UnreadNotificationsResDto[]>({
      url: `/api/${userId}/Notification/all`,
      method: 'GET'
    })
    return response.data;
  },

  async updateNotificationState(userId: string, messageId: string): Promise<void> {
    const response = await ApiService.fetchData<void, void>({
      url: `/api/${userId}/Notification/read/${messageId}`,
      method: 'POST'
    })
    return response.data;
  }
}
