import { ApiService, BaseAuth } from '@bloxima/ui-library';

export const AuthService = {
  async signIn(username: string, password: string): Promise<BaseAuth.ApiSignInResponse> {
    const payload = {
      username: username.trim(),
      password: password.trim()
    }

    const response = await ApiService.fetchData<{ username: string, password: string }, BaseAuth.ApiSignInResponse>({
      url: '/user/signin',
      method: 'post',
      data: payload
    });

    return response.data;
  },

  async signInWithGoogle(accessToken: string): Promise<BaseAuth.ApiSignInResponse> {
    const response = await ApiService.fetchData<{ token: string }, BaseAuth.ApiSignInResponse>({
      url: '/user/HandleGoogleLogin',
      method: 'post',
      data: { token: accessToken }
    });

    return response.data;
  },

  async signUp(username: string, password: string, name: string): Promise<void> {
    const payload = {
      username: username.trim(),
      password: password.trim(),
      name: name && name.trim()
    }

    await ApiService.fetchData<BaseAuth.ApiSignUpRequest, void>({
      url: '/user/register',
      method: 'post',
      data: payload
    });
  },

  async forgotPassword(email: string): Promise<BaseAuth.SignInResponse> {
    const payload = {
      email: email.trim()
    }

    try {
      await ApiService.fetchData<BaseAuth.ApiForgotPasswordRequest, BaseAuth.SignInResponse>({
        url: '/user/forgot-password',
        method: 'post',
        data: payload
      });

      return {
        status: 'success'
      } satisfies BaseAuth.SignInResponse;
    } catch (e: any) {
      return {
        status: 'failed',
        error_code: e?.response?.data?.error_code,
        message: e?.response?.data?.description
      } satisfies BaseAuth.SignInResponse;
    }
  },

  async refreshToken(refreshToken: string): Promise<BaseAuth.ApiSignInResponse> {
    const payload = {
      refreshToken: refreshToken
    }

    const response = await ApiService.fetchData<{ refreshToken: string }, BaseAuth.ApiSignInResponse>(
      {
        url: '/user/refresh-token',
        method: 'post',
        data: payload
      }
    )

    return response.data
  },

  async userinfo(): Promise<BaseAuth.ApiUserInfo> {
    const response = await ApiService.fetchData<void, BaseAuth.ApiUserInfo>(
      {
        url: '/user/user',
        method: 'post'
      }
    )
    return response.data;
  },

  async resetPassword(
    email: string,
    token: string,
    password: string
  ): Promise<BaseAuth.SignInResponse> {
    const payload = {
      email: email.trim(),
      token: token.trim(),
      newPassword: password.trim()
    }

    try {
      await ApiService.fetchData<{ email: string; token: string; newPassword: string }, void>({
        url: '/user/reset-password',
        method: 'post',
        data: payload
      });
      return {
        status: 'success'
      } satisfies BaseAuth.SignInResponse;
    } catch (e: any) {
      return {
        status: 'failed',
        error_code: e?.response?.data?.error_code,
        message: e?.response?.data?.description
      } satisfies BaseAuth.SignInResponse
    }
  },

  async changePassword(
    username: string,
    oldPassword: string,
    newPassword: string
  ): Promise<BaseAuth.SignInResponse> {
    const payload = {
      username: username.trim(),
      oldPassword: oldPassword.trim(),
      newPassword: newPassword.trim()
    }

    try {
      await ApiService.fetchData<
        { username: string; oldPassword: string; newPassword: string },
        void
      >({
        url: '/user/change-password',
        method: 'post',
        data: payload
      });

      return {
        status: 'success'
      } satisfies BaseAuth.SignInResponse;
    } catch (e: any) {
      return {
        status: 'failed',
        error_code: e?.response?.data?.error_code,
        message: e?.response?.data?.description
      } satisfies BaseAuth.SignInResponse
    }
  },

  async changeName(dto : {walletAddress: string|Promise<void>, userId: string, name: string}): Promise<void> {

    await ApiService.fetchData<{ userId: string; name: string }, void>({
      url: '/user',
      method: 'put',
      data: dto
    });
  }
};
