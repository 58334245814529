import React, { useEffect, useState } from 'react';
import {
  BaseAuth,
  BaseAuthContextProvider,
  BaseTemplateContextProvider,
  BaseThemeContextProvider,
  Layout,
  LayoutTypes
} from '@bloxima/ui-library';
import { protectedRoutes, publicRoutes } from './configs/routes.config';
import navigationConfig from './configs/navigation.config';
import navigationIcon from './configs/navigation-icon.config';
import { BrowserRouter } from 'react-router-dom';
import '@bloxima/ui-library/dist/styles/base-template.min.css';
import './assets/styles/app.min.css';
import { appConfig } from './configs/app.config';
import { AuthService } from './service/auth.service';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { NotificationsService } from './service/notifications.service';

function App() {
  const [jwtToken, setJwtToken] = useState<string>('');
  const [userId, setUserId] = useState<string>('');
  const [notificationCount, setNotificationCount] = useState<number>(0);

  useEffect(() => {
    notifyUser()
  }, [jwtToken]);

  async function signIn(data: BaseAuth.ApiSignInRequest): Promise<BaseAuth.ApiSignInResponse> {
    const response = await AuthService.signIn(data.username, data.password);
    // await signalRService.startConnection(response.accessToken, response.userId);
    setUserId(response.userId);
    setJwtToken(response.accessToken);

    return {
      ...response,
      username: data.username,
      expireTime: Math.round(response.expireTime),
      socialLogin: data.isGoogleAuth
    };
  }


  async function notifyUser(): Promise<number> {
    const count = await NotificationsService.getUnreadNotificationCount(userId);
    setNotificationCount(count);
    return count
  }


  async function signUp(data: BaseAuth.ApiSignUpRequest): Promise<void> {
    await AuthService.signUp(data.username, data.password, data.name);
  }

  async function forgotPassword(
    data: BaseAuth.ApiForgotPasswordRequest
  ): Promise<BaseAuth.ApiForgotPasswordResponse> {
    return await AuthService.forgotPassword(data.email);
  }

  async function refreshToken(refreshToken: string): Promise<BaseAuth.ApiSignInResponse> {
    const response = await AuthService.refreshToken(refreshToken);
    setJwtToken(response.accessToken);
    setUserId(response.userId);
    const count = await NotificationsService.getUnreadNotificationCount(response.userId);
    setNotificationCount(count);

    return {
      ...response,
      username: 'refresh',
      expireTime: Math.round(response.expireTime)
    }
  }

  async function userInfo(): Promise<BaseAuth.ApiUserInfo> {
    return await AuthService.userinfo();
  };

  return (
    <GoogleOAuthProvider clientId="67858306559-q3smec1u1gl0lu12gvp48ipfnc5l2tvn.apps.googleusercontent.com">
      <BrowserRouter>
        <BaseTemplateContextProvider
          updateJwt={jwtToken}
          notifyUser={notifyUser}
          updateNotificationCount={notificationCount}
          initialLayoutType={LayoutTypes.CLASSIC}
          publicRoutes={publicRoutes}
          protectedRoutes={protectedRoutes}
          navigationConfig={navigationConfig}
          navigationIcon={navigationIcon}
          authEnabled={true}
          appConfig={appConfig}
        >
          <BaseThemeContextProvider>
            <BaseAuthContextProvider
              apiSignIn={signIn}
              apiSignUp={signUp}
              apiForgotPassword={forgotPassword}
              apiRefreshToken={refreshToken}
              apiUserInfo={userInfo}
            >
              <Layout/>
            </BaseAuthContextProvider>
          </BaseThemeContextProvider>
        </BaseTemplateContextProvider>
      </BrowserRouter>
    </GoogleOAuthProvider>
  )
}

export default App
