import React from 'react';
import { RoutesModel } from '@bloxima/ui-library';
import { authRoute } from './authRoute';

export const publicRoutes: RoutesModel[] = [...authRoute]

export const protectedRoutes: RoutesModel[] = [
  {
    key: 'dashboard',
    path: '/',
    component: React.lazy(() => import('../views/Dashboard')),
    authority: []
  },
  {
    key: 'application',
    path: '/application',
    component: React.lazy(() => import('../views/Application')),
    authority: []
  },
  {
    key: 'endpoint',
    path: '/endpoint',
    component: React.lazy(() => import('../views/Endpoint')),
    authority: []
  },
  {
    key: 'flow',
    path: '/flow',
    component: React.lazy(() => import('../views/Flow')),
    authority: []
  },
  {
    key: 'change-password',
    path: '/change-password',
    component: React.lazy(() => import('../views/User/ChangePassword')),
    authority: []
  },
  {
    key: 'flow-log',
    path: '/report/flow-log',
    component: React.lazy(() => import('../views/Report/FlowLog')),
    authority: []
  },
  {
    key: 'event-log',
    path: '/report/event-log',
    component: React.lazy(() => import('../views/Report/EventLog')),
    authority: []
  },

  {
    key: 'web3',
    path: '/web3',
    component: React.lazy(() => import('../views/Web3')),
    authority: []
  },
  {
    key: 'settings',
    path: '/settings',
    component: React.lazy(() => import('../views/Settings')),
    authority: []
  },
  {
    key: 'pipeline',
    path: '/pipeline',
    component: React.lazy(() => import('../views/Pipeline')),
    authority: []
  },
  {
    key: 'create',
    path: '/nft/create',
    component: React.lazy(() => import('../views/Nft/Create')),
    authority: []
  },
  {
    key: 'tokenization',
    path: '/tokenization/create',
    component: React.lazy(() => import('../views/TokenIzation/Create')),
    authority: []
  },
  {
    key: 'transfers',
    path: '/tokenization/transfers',
    component: React.lazy(() => import('../views/TokenIzation/Transfers')),
    authority: []
  },
  {
    key: 'nft-view',
    path: '/report/nft-view',
    component: React.lazy(() => import('../views/Nft/View')),
    authority: []
  },
  {
    key: 'collections',
    path: '/collections',
    component: React.lazy(() => import('../views/Collections')),
    authority: []
  },
  {
    key: 'notifications',
    path: '/notifications',
    component: React.lazy(() => import('../views/Notifications')),
    authority: []
  },


  // {
  //   key: 'my-nft-list',
  //   path: '/my-nft-list',
  //   component: React.lazy(() => import('../views/MyNftList')),
  //   authority: [],
  // },
]
